body, html {
    margin:0;
    padding:0;
    background-color: #faf9f8;
}

a, a:link, a:visited {
    text-decoration: none;
}

*{
    box-sizing:border-box;
}

.markdown strong > em,
.markdown em > strong,
.markdown b > i,
.markdown i > b {
    font-style: normal;
    font-weight: normal;
    text-decoration: underline;
}

a {
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: inherit;
    font-weight: inherit;
    color: rgb(0, 120, 212);
    outline: none;
    text-decoration: none;
    background: none transparent;
    border-top: none;
    border-right: none;
    border-left: none;
    border-image: initial;
    cursor: pointer;
    display: inline;
    margin: 0px;
    overflow: inherit;
    padding: 0px;
    text-align: left;
    text-overflow: inherit;
    border-bottom: 1px solid transparent;
    user-select: text;
}

a:hover, a:active {
    color: rgb(0, 69, 120);
    text-decoration: underline;
}
